import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'

export function createContext<T>() {
  const context = React.createContext<T | undefined>(undefined)
  function useContext() {
    const c = React.useContext(context)
    if (c === undefined) {
      throw new Error('useContext must be inside a Provider with a value')
    }
    return c
  }
  return [useContext, context.Provider] as const // make TypeScript infer a tuple, not an array of union types
}

export function createUpdatableContext<T>(defaultValue?: T) {
  if (defaultValue === undefined) {
    throw new Error("Can't create updatable context without default value")
  }
  type UpdateType = Dispatch<SetStateAction<T>>
  const defaultUpdate: UpdateType = () => defaultValue as T

  const context = React.createContext([defaultValue as T, defaultUpdate] as const)
  function useContext() {
    const c = React.useContext(context)
    if (c === undefined) {
      throw new Error('useContext must be inside a Provider with a value')
    }
    return c
  }

  const Provider = ({ children, ...props }: PropsWithChildren<Partial<T>>) => {
    const dispatch = useState<T>({ ...defaultValue, ...props })
    return (
      <context.Provider value={dispatch} {...props}>
        {children}
      </context.Provider>
    )
  }
  return [useContext, Provider] as const // make TypeScript infer a tuple, not an array of union types
}

'use client'

import dynamic from 'next/dynamic'
import slugify from 'slugify'
import { twMerge } from 'tailwind-merge'

import { Theme } from '@/data/shared'
import { createContext } from '@/utils/createContext'
import { GlobalAppContext } from './GlobalAppContext'

export type ScrollDirection = 'up' | 'down'

export type SectionProperties = {
  theme?: Theme
  withSideNav?: boolean
  name?: string
}

type SectionContainerProps = SectionProperties & {
  children: React.ReactNode
  sideNavClassName?: string
  className?: string
}

const StickySideNav = dynamic(() => import('./StickySideNav').then(({ StickySideNav }) => StickySideNav), {
  ssr: false,
})

export const [useSection, SectionProvider] = createContext<SectionProperties>()

export function SectionContainer({
  children,
  className,
  name,
  theme = 'light',
  withSideNav,
  sideNavClassName,
}: SectionContainerProps) {
  const current = GlobalAppContext.useSelector(
    ({ context: { currentSection } }) => !!currentSection && currentSection === name,
  )
  return (
    <SectionProvider value={{ name, theme, withSideNav }}>
      <section
        data-section={name}
        data-testid={name ? `section-${slugify(name, { lower: true })}` : 'section'}
        aria-current={current}
        data-theme={theme}
        className={twMerge(
          'relative flex w-full flex-1 flex-col items-stretch overflow-x-clip first-of-type:top-0 last-of-type:-mb-px peer-[.fixed]:top-0',
          className,
          theme === 'dark' ? 'dark bg-neutral-850 text-neutral-50' : 'bg-neutral-50 text-neutral-850',
        )}
      >
        {withSideNav && <StickySideNav className={sideNavClassName} />}
        {children}
      </section>
    </SectionProvider>
  )
}

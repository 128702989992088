import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react'
import Link from 'next/link'

import { cn } from '@/lib/utils'

export type ButtonProps = (AnchorHTMLAttributes<HTMLAnchorElement> | ButtonHTMLAttributes<HTMLButtonElement>) & {
  icon?: React.ReactNode
  collapse?: boolean
}

export function Button({ className, icon, children, collapse, ...props }: ButtonProps) {
  const buttonClasses = cn(
    'flex items-center justify-center focus:outline-none group focus-visible:ring-current focus-visible:ring-2',
    className,
  )

  const buttonContent = (
    <>
      {icon ? icon : null}
      {children ? (
        <span className={cn('shrink-0', collapse ? 'hidden sm:block' : 'block', icon && 'peer')}>{children}</span>
      ) : null}
    </>
  )

  if ('href' in props && props.href) {
    const href = props.href
    return typeof props.href === 'string' && props.href.startsWith('http') ? (
      <a className={buttonClasses} href={href} {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}>
        {buttonContent}
      </a>
    ) : (
      <Link className={buttonClasses} href={href} {...props}>
        {buttonContent}
      </Link>
    )
  }
  return (
    <button className={buttonClasses} {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {buttonContent}
    </button>
  )
}

import {
  backIn,
  backInOut,
  backOut,
  circIn,
  circInOut,
  circOut,
  cubicBezier,
  easeIn,
  easeInOut,
  easeOut,
} from 'framer-motion'

const linear = cubicBezier(0, 0, 1, 1)
const easings = { linear, backOut, backIn, backInOut, circIn, circOut, circInOut, easeIn, easeOut, easeInOut } as const

export type Ease = keyof typeof easings

export const ease = (easing: Ease) => easings[easing]

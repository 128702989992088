interface LogoProps {
  className?: string
}

export function Logo({ className }: LogoProps) {
  return (
    <svg
      width="132"
      height="28"
      viewBox="0 0 132 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        className="fill-current"
        d="M0 27.527c1.912-1.026 2.328-2.209 2.328-4.812V4.89C2.328 2.287 1.912 1.104 0 .08V0h11.555C21.738 0 28.97 4.496 28.97 13.606c0 9.543-7.399 14-16.833 14H0v-.08Zm11.555-.947c6.359 0 10.681-4.14 10.681-12.974 0-8.045-4.28-12.58-11.055-12.58H8.562V26.58h2.993ZM62.924 16.958C62.924 24.214 58.31 28 51.577 28c-7.481 0-12.344-3.747-12.344-11.121V4.89c0-2.603-.416-3.786-2.328-4.811V0h10.931v.079C45.925 1.104 45.51 2.287 45.51 4.89v11.792c0 5.797 2.452 9.188 7.69 9.188 4.862 0 8.478-3.194 8.478-9.07V5.994c0-3.115-1.039-4.969-3.325-5.915V0h7.69v.079c-2.12 1.025-3.118 2.8-3.118 5.758v11.12ZM75.266 0H96.69v4.162H80.14v7.335h15.115v3.962H80.189v7.966h16.479v4.18H75.263V0h.002ZM116.391 4.18h-10.64V0h26.246v4.128h-10.682v23.478h-4.924V4.18Z"
      />
    </svg>
  )
}

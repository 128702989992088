'use client'

import { useLayoutEffect, useRef } from 'react'
import deepmerge from '@fastify/deepmerge'
import { motion, MotionValue, useScroll, useSpring, useTransform, useWillChange } from 'framer-motion'

import { cn } from '@/lib/utils'
import { ease, Ease } from '@/utils/easings'
import { useBreakpoint } from '@/utils/tailwind'

export function useParallax(
  value: MotionValue<number>,
  range: [string | number, string | number],
  easing: Ease = 'linear',
) {
  return useTransform(value, [0, 1], range, { ease: ease(easing) })
}

type ScrollOffset = NonNullable<Parameters<typeof useScroll>[0]>['offset']
type ParallaxTypes = 'x' | 'y' | 'opacity' | 'rotate' | 'scale' | 'filter'
type DisableMobile = Partial<Record<ParallaxTypes, boolean>>
type SpringConfig = Parameters<typeof useSpring>[1]

export interface ParallaxContainerProperties {
  debug?: boolean
  startY?: number | string
  endY?: number | string
  startX?: number | string
  endX?: number | string
  className?: string
  offset?: ScrollOffset
  ease?: Ease
  easeX?: Ease
  easeY?: Ease
  easeOpacity?: Ease
  startOpacity?: number
  endOpacity?: number
  easeRotation?: Ease
  startRotation?: number
  endRotation?: number
  startScale?: number
  endScale?: number
  easeScale?: Ease
  startFilter?: string
  endFilter?: string
  easeFilter?: Ease
  disableMobile?: DisableMobile
  spring?: SpringConfig
}

interface ParallaxContainerProps extends ParallaxContainerProperties {
  children: React.ReactNode
}

const merge = deepmerge({})

const defaults = {
  startY: 0,
  endY: 0,
  startX: 0,
  endX: 0,
  startOpacity: 1,
  endOpacity: 1,
  startRotation: 0,
  endRotation: 0,
  startScale: 1,
  endScale: 1,
  endFilter: 'blur(0px)',
  startFilter: 'blur(0px)',
  spring: { stiffness: 500, damping: 50 } as SpringConfig,
} as const

export function ParallaxContainer({
  debug = false,
  disableMobile,
  className,
  children,
  offset = ['start end', 'end start'],
  ease,
  easeX = ease,
  easeY = ease,
  easeOpacity = ease,
  easeRotation = ease,
  easeScale = ease,
  easeFilter = ease,
  spring = {},
  ...props
}: ParallaxContainerProps) {
  const isMobile = !useBreakpoint('sm')

  const {
    startX,
    endX,
    startY,
    endY,
    startOpacity,
    endOpacity,
    startRotation,
    endRotation,
    startScale,
    endScale,
    endFilter,
    startFilter,
  } = {
    ...defaults,
    ...props,
    ...(isMobile && disableMobile?.x ? { startX: defaults.startX, endX: defaults.endX } : {}),
    ...(isMobile && disableMobile?.y ? { startY: defaults.startY, endY: defaults.endY } : {}),
    ...(isMobile && disableMobile?.opacity
      ? { startOpacity: defaults.startOpacity, endOpacity: defaults.endOpacity }
      : {}),
    ...(isMobile && disableMobile?.rotate
      ? { startRotation: defaults.startRotation, endRotation: defaults.endRotation }
      : {}),
    ...(isMobile && disableMobile?.scale ? { startScale: defaults.startScale, endScale: defaults.endScale } : {}),
    ...(isMobile && disableMobile?.filter ? { startFilter: defaults.startFilter, endFilter: defaults.endFilter } : {}),
  }

  const ref = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({ target: ref, offset })

  {
    debug &&
      scrollYProgress.on('change', (v) => {
        console.log('scrollYProgress', '.'.repeat(Math.round(v * 100)))
      })
  }
  const willChange = useWillChange()
  const scrollY = useSpring(scrollYProgress, merge(defaults.spring, spring))

  const x = useParallax(scrollY, [startX, endX], easeX)
  const y = useParallax(scrollY, [startY, endY], easeY)
  const opacity = useParallax(scrollY, [startOpacity, endOpacity], easeOpacity)
  const rotate = useParallax(scrollY, [startRotation, endRotation], easeRotation)
  const scale = useParallax(scrollY, [startScale, endScale], easeScale)
  const filter = useParallax(scrollY, [startFilter, endFilter], easeFilter)

  useLayoutEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [])

  return (
    <div ref={ref} className="relative flex flex-1">
      <motion.div
        style={{ x, y, opacity, rotate, scale, filter, willChange }}
        className={cn('transform-gpu', className)}
      >
        {children}
      </motion.div>
    </div>
  )
}
